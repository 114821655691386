import { DEFAULT_PATHS } from 'config.js';
import { lazy } from 'react';


// Rutas
const DashboardGettingStarted = lazy( () => import("views/dashboard/DashboardGettingStarted") ); // Pagina de inicio

const Profile = lazy( () => import("views/masters/profiles/Profile") ); // Maestro de perfiles

const File = lazy( () => import("views/masters/positions/File") ); // Maestro de archivos

const Parameter = lazy( () => import("views/masters/parameter/Parameter") ); // Maestro de cargos

const Search = lazy( () => import("views/masters/search/Search") ); // Maestro de consultas

const SearchEndowment = lazy( () => import("views/masters/searchEndowment/Search") ); // Maestro de consultas
const transferEndowment = lazy( () => import("views/masters/transferEndowment/Search") ); // Maestro de consultas

const Rol = lazy( () => import("views/masters/rol/Rol") ); // Maestro de roles

const Shared = lazy( () => import("views/masters/shared/Shared") ); // Maestro de compartidos

const Folders = lazy( () => import("views/masters/employee/Employee") ); // Maestro de compartidos

const MyShared = lazy( () => import("views/masters/myshared/MyShared") ); // Maestro de mis compartidos

const ServicesStorageFolder = lazy( () => import("views/masters/positions/ServicesStorageFolder") );
const User = lazy(() => import("views/masters/user/user"));
const Type = lazy(() => import("views/masters/type/type"));
const Subtype = lazy(() => import("views/masters/subtype/subtypes"));
const Estadísticas = lazy(() => import("views/masters/analisys/Analisys"));
function Transformar() {


  
  if (localStorage.getItem("persist-path") == undefined) {
   var r = {
      mainMenuItems: [
        {
          path: DEFAULT_PATHS.APP,
          exact: true,
          redirect: true,
          to: `/home`,
          protected: true,
          component: DashboardGettingStarted,
        },
        {
          path: `home`,
          component: DashboardGettingStarted,
          label: "menu.home",
          icon: "home",
          protected: true,
        },
      ],
      sidebarItems: []

    };


  } else {
   
   var r = JSON.parse(localStorage.getItem("persist-path"));

    if ( r.mainMenuItems.find((item) => item.path === "/home") !== undefined ) {
      r.mainMenuItems.find((item) => item.path === "/home").component = DashboardGettingStarted;
    } 

    if ( r.mainMenuItems.find((item) => item.path === "/masters") !== undefined ) {

      if (
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          ?.subs?.find((item) => item.path === "/user") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          .subs.find((item) => item.path === "/user").component = User;
      }

      if ( r.mainMenuItems.find((item) => item.path === "/masters")?.subs?.find((item) => item.path === "/profile") !== undefined ) {
        r.mainMenuItems.find((item) => item.path === "/masters").subs.find((item) => item.path === "/profile").component = Profile;
      }

      if ( r.mainMenuItems.find((item) => item.path === "/masters")?.subs?.find((item) => item.path === "/parameter") !== undefined ) {
        r.mainMenuItems.find((item) => item.path === "/masters").subs.find((item) => item.path === "/parameter").component = Parameter;
      }

      if ( r.mainMenuItems.find((item) => item.path === "/masters")?.subs?.find((item) => item.path === "/rol") !== undefined ) {
        r.mainMenuItems.find((item) => item.path === "/masters").subs.find((item) => item.path === "/rol").component = Rol;
      }

  
      if ( r.mainMenuItems.find((item) => item.path === "/masters")?.subs?.find((item) => item.path === "/employee") !== undefined ) {
        r.mainMenuItems.find((item) => item.path === "/masters").subs.find((item) => item.path === "/employee").component = Folders;
      }


    } 
    
    if ( r.mainMenuItems.find((item) => item.path === "/management") !== undefined ) {
      
      if (
        r.mainMenuItems
          .find((item) => item.path === "/management")
          ?.subs?.find((item) => item.path === "/type") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/management")
          .subs.find((item) => item.path === "/type").component =
          Type;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/management")
          ?.subs?.find((item) => item.path === "/subtype") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/management")
          .subs.find((item) => item.path === "/subtype").component =
          Subtype;
      }
      
      if ( r.mainMenuItems.find((item) => item.path === "/management")?.subs?.find((item) => item.path === "/file") !== undefined ) {
        r.mainMenuItems.find((item) => item.path === "/management").subs.find((item) => item.path === "/file").component = File;
      }


      if ( r.mainMenuItems.find((item) => item.path === "/management")?.subs?.find((item) => item.path === "/parameter") !== undefined ) {
        r.mainMenuItems.find((item) => item.path === "/management").subs.find((item) => item.path === "/parameter").component = Parameter;
      }

      if ( r.mainMenuItems.find((item) => item.path === "/management")?.subs?.find((item) => item.path === "/search") !== undefined ) {
        r.mainMenuItems.find((item) => item.path === "/management").subs.find((item) => item.path === "/search").component = Search;
      }

      if ( r.mainMenuItems.find((item) => item.path === "/management")?.subs?.find((item) => item.path === "/searchEndowment") !== undefined ){
        r.mainMenuItems.find((item) => item.path === "/management").subs.find((item) => item.path === "/searchEndowment").component = SearchEndowment;  
      }

      if ( r.mainMenuItems.find((item) => item.path === "/management")?.subs?.find((item) => item.path === "/transferEndowment") !== undefined ){
        r.mainMenuItems.find((item) => item.path === "/management").subs.find((item) => item.path === "/transferEndowment").component = transferEndowment;  
      }

      
      

      if ( r.mainMenuItems.find((item) => item.path === "/management")?.subs?.find((item) => item.path === "/analisys") !== undefined ) {
        r.mainMenuItems.find((item) => item.path === "/management").subs.find((item) => item.path === "/analisys").component = Estadísticas;
      }
    }
    
    if ( r.mainMenuItems.find((item) => item.path === "/shareds") !== undefined ) {
      if ( r.mainMenuItems.find((item) => item.path === "/shareds")?.subs?.find((item) => item.path === "/shared") !== undefined ) {
        r.mainMenuItems.find((item) => item.path === "/shareds").subs.find((item) => item.path === "/shared").component = Shared;
      } 

      if ( r.mainMenuItems.find((item) => item.path === "/shareds")?.subs?.find((item) => item.path === "/Myshared") !== undefined ) {
        r.mainMenuItems.find((item) => item.path === "/shareds").subs.find((item) => item.path === "/Myshared").component = MyShared;
      }
    }

    localStorage.setItem("persist-path", JSON.stringify(r));
  }
  return r;

}


const appRoot = DEFAULT_PATHS.APP.endsWith("/")
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const routesAndMenuItems = Transformar();

export default routesAndMenuItems;

export { routesAndMenuItems, Transformar };
